// component
import SvgColor from '../../../components/svg-color';
import { IoNotificationsSharp } from "react-icons/io5";
import { SiGoogleanalytics } from "react-icons/si";
import { TbArrowsExchange } from "react-icons/tb";


// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Əsas səhifə',
    path: '/dashboard/app',
    icon: icon('ic_home'),
  },
  {
    title: 'Canlı çat',
    path: '/dashboard/chat',
    icon: icon('ic_chat_2'),
    permission: 'chat',
  },
  {
    title: 'Admin İşçi Vəzifələri',
    path: '/dashboard/admin-panel-employees-role',
    icon: icon('ic_employee-role'),
    permission: 'role',
  },
  {
    title: 'Admin Panel İşçiləri',
    path: '/dashboard/admin-panel-employees',
    icon: icon('ic_employees'),
    permission: 'admin_user',
  },
  {
    title: 'Mağaza Panel İşçiləri',
    path: '/dashboard/store-panel-employees',
    icon: icon('ic_employees'),
    permission: 'admin_user',
  },
  {
    title: 'İstifadəçilər',
    path: '/dashboard/user',
    icon: icon('ic_user'),
    permission: 'user',
  },
  {
    title: 'Bildirişlər',
    path: '/dashboard/notifications',
    icon: <IoNotificationsSharp size={22}/>,
    permission: 'user',
  },
  {
    title: 'Analitika',
    path: '/dashboard/analytics',
    icon: <SiGoogleanalytics size={20}/>,
    permission: 'user',
  },
  {
    title: 'Ana səhifə kontenti',
    path: '/dashboard/home-content',
    icon: <TbArrowsExchange size={22}/>,
    permission: 'user',
  },
  {
    title: 'Ailə üzvləri',
    path: '/dashboard/family',
    icon: icon('ic_bloggers'),
    permission: 'user',
  },
  {
    title: 'Nə üçün',
    path: '/dashboard/purpose',
    icon: icon('ic_terms'),
    permission: 'user',
  },
  {
    title: 'Kim üçün',
    path: '/dashboard/recipient',
    icon: icon('ic_terms'),
    permission: 'user',
  },
  {
    title: 'Statik səhifələr',
    path: '/dashboard/page',
    icon: icon('ic_terms'),
    permission: 'user',
  },
  {
    title: 'İnfluenserlər',
    path: '/dashboard/blogger',
    icon: icon('ic_blogger'),
    permission: 'user',
  },
  {
    title: 'İnfluenser Müraicətləri',
    path: '/dashboard/blogger-requests',
    icon: icon('ic_bloggers'),
    permission: 'user',
  },
  {
    title: 'İnfluenser Listləri',
    path: '/dashboard/blogger-lists',
    icon: icon('ic_list'),
    permission: 'user',
    disabled: true,
  },

  {
    title: 'Məhsullar',
    path: '/dashboard/product',
    icon: icon('ic_product'),
    permission: 'product',
  },

  {
    title: 'Sifarişlər',
    path: '/dashboard/order',
    icon: icon('ic_orders'),
    permission: 'order',
  },
  {
    title: 'Kateqoriyalar',
    path: '/dashboard/category',
    icon: icon('ic_category'),
    permission: 'category',
  },
  {
    title: 'Brendlər',
    path: '/dashboard/brand',
    icon: icon('ic_category'),
    permission: 'category',
  },
  {
    title: 'Promolar',
    path: '/dashboard/promo',
    icon: icon('ic_promo'),
    permission: 'promo',
  },
  {
    title: 'Reklam Bannerləri',
    path: '/dashboard/banners',
    icon: icon('ic_banner_reklam'),
    permission: 'banner',
  },
  {
    title: 'Offerlər',
    path: '/dashboard/offers',
    icon: icon('ic_banner_reklam'),
    permission: 'banner',
  },

  {
    title: 'Məhsul Rəyləri',
    path: '/dashboard/comment',
    icon: icon('ic_comment'),
    permission: 'comment',
  },

  {
    title: 'İadə Müraciətləri',
    path: '/dashboard/request/refund',
    icon: icon('ic_refund'),
    permission: 'refund',
  },
  {
    title: 'Əlaqə müraciətləri',
    path: '/dashboard/request/contact-us',
    icon: icon('ic_message'),
    permission: 'report',
  },
  {
    title: 'İstifadəçi yardım müraciətləri',
    path: '/dashboard/request/user-reports',
    icon: icon('ic_chat'),
    permission: 'report',
  },

  {
    title: 'Əlaqə səhifəsi',
    path: '/dashboard/contact',
    icon: icon('ic_contact'),
    permission: 'content',
  },
  {
    title: 'F.A.Q səhifəsi',
    path: '/dashboard/faq',
    icon: icon('ic_faq'),
    permission: 'content',
  },
  {
    title: 'Məhsul yaradılması şərtləri',
    path: '/dashboard/product-create-terms',
    icon: icon('ic_terms'),
    permission: 'content',
  },

  {
    title: 'Çatdırılma şərtləri',
    path: '/dashboard/delivery-terms',
    icon: icon('ic_terms'),
    permission: 'content',
  },
  {
    title: 'Ödəmə şərtləri',
    path: '/dashboard/payment-terms',
    icon: icon('ic_terms'),
    permission: 'content',
  },
  {
    title: 'Sifariş şərtləri',
    path: '/dashboard/order-terms',
    icon: icon('ic_terms'),
    permission: 'content',
  },
  {
    title: 'Qeydiyyat şərtləri',
    path: '/dashboard/register-terms',
    icon: icon('ic_terms'),
    permission: 'content',
  },
  {
    title: 'Search Engine Optimization',
    path: '/dashboard/search-engine-optimization',
    icon: icon('ic_seo'),
    permission: 'seo',
  },
  {
    title: 'SEO Scripts',
    path: '/dashboard/seo-scripts',
    icon: icon('ic_tag'),
    permission: 'seo',
  },
  {
    title: 'Mobil versiya',
    path: '/dashboard/mobile-details',
    icon: icon('ic_mobile'),
    permission: 'content',
  },
];

export default navConfig;
